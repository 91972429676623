import { Box } from '@material-ui/core'
import PagesLayout from '../components/layouts/PagesLayout'
import ProjectPostCard from '../components/templates/ProjectPostCard'
import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -15,
    padding: theme.spacing(1),
  },
}))

export default function CourseWork({ data: { allMdx } }) {
  const { edges: posts } = allMdx
  const classes = useStyles()

  return (
    <PagesLayout
      SEO={{
        title: 'Course Work',
      }}
    >
      <div className={classes.root}>
        <Box mb={2}>
          {posts.map(({ node: post }) => (
            <Box my={1} key={post.id}>
              <ProjectPostCard
                frontmatter={post?.frontmatter}
                bodyContent={post.body}
              />
            </Box>
          ))}
        </Box>
      </div>
    </PagesLayout>
  )
}

export const query = graphql`
  {
    allMdx(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { fileAbsolutePath: { regex: "/(blog-project-posts)/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            course
            updatedAt(formatString: "MMMM DD, YYYY")
            title
            isVisible
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            featureImageDescription
          }
          body
        }
      }
    }
  }
`
