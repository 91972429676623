import PropTypes from 'prop-types'

export const PROJECT_POSTS_FRONT_MATTER_PROP_TYPES = {
  course: PropTypes.string.isRequired,
  featuredImage: PropTypes.object.isRequired,
  featureImageDescription: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
}

export const PROJECT_POSTS_FRONT_MATTER_DEFAULT_PROPS = {
  tags: [],
  isVisible: true,
}

export const BLOG_POSTS_FRONT_MATTER_PROP_TYPES = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  tags: PropTypes.array,
  featuredImage: PropTypes.object,
  featureImageDescription: PropTypes.string,
  shortDescription: PropTypes.string,
  isVisible: PropTypes.bool,
}

export const BLOG_POSTS_FRONT_MATTER_DEFAULT_PROPS = {
  featuredImage: null,
  featureImageDescription: 'blog post image',
  tags: [],
  shortDescription: null,
  isVisible: true,
}
