import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import {
  PROJECT_POSTS_FRONT_MATTER_DEFAULT_PROPS,
  PROJECT_POSTS_FRONT_MATTER_PROP_TYPES,
} from '../../utils/constants/frontmatter'

import { GatsbyImage } from 'gatsby-plugin-image'
import MDXLayout from '../layouts/MDXLayout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    padding: theme.spacing(2),
    overFlow: 'contained',
  },
  title: {
    fontWeight: theme.fontWeightBold,
    padding: 0,
    margin: 0,
  },
  postBodyContent: {
    maxHeight: 300,
    overflowY: 'scroll',
  },
  divider: {
    color: theme.palette.secondary.main,
    height: 2,
  },
  img: {},
}))

export default function PostProjectCard({ frontmatter, bodyContent }) {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Box className={classes.img}>
            <GatsbyImage
              image={frontmatter.featuredImage.childImageSharp.gatsbyImageData}
              alt={frontmatter.featureImageDescription}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card elevation={0}>
            <CardHeader
              title={
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="h2"
                >
                  {frontmatter.title}
                </Typography>
              }
              subheader={`Course: ${frontmatter.course}`}
            />
            <Divider className={classes.divider} variant="middle" />
            <Box mb={2}>
              <CardContent className={classes.postBodyContent}>
                <MDXLayout>
                  <MDXRenderer>{bodyContent}</MDXRenderer>
                </MDXLayout>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  )
}

PostProjectCard.propTypes = {
  /**
   * Frontmatter prop types
   */
  frontmatter: PropTypes.shape({ ...PROJECT_POSTS_FRONT_MATTER_PROP_TYPES }),
  bodyContent: PropTypes.string,
}
PostProjectCard.defaultProps = {
  frontmatter: PROJECT_POSTS_FRONT_MATTER_DEFAULT_PROPS,
  bodyContent: null,
}
